import React from "react";
import debounce from 'lodash/debounce';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
} from "react-google-maps";
import MapMarker from './MapMarker';

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  onMapUpdate = debounce(() => {
    const map = this.mapRef.current;
    const bounds = map.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    this.props.onMapUpdate(sw, ne);
  }, 250)

  render() {
    return (
      <GoogleMap
        ref={this.mapRef}
        onCenterChanged={this.onMapUpdate}
        onZoomChanged={this.onMapUpdate}
        defaultZoom={11}
        options={{mapTypeControl: false, streetViewControl: false}}
        defaultCenter={{ lat: 45.5143802, lng: -73.6617979 }}
      >
        {
          this.props.locations.map(location => (
            <MapMarker
              key={location.Id}
              location={location}
              onClick={this.props.onMarkerClick}
            />
          ))
        }
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(Map));