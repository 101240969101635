import React, { Component } from "react";
import {Row, Col, Rate, Button, Icon} from 'antd';

/*AdditionalInformation: ""
Age: {Max: 0, Min: 8, Months: false}
AgeSummary: "8+"
CategoryName: "Parkour"
Description: "<p>Cours dirig&eacute; o&ugrave; les enfants apprennent &agrave; faire des acrobaties &agrave; l&#39;aide des &eacute;quipements gymnique.</p>↵"
Difficulty: 0
DropInPrice: 0
Duration: 0
EndDate: "2019-06-19T22:00:00Z"
HasDropInEnabled: false
HasSessionEnabled: true
Id: 2098673
Keywords: [{…}]
Latitude: 45.3771278
Longitude: -73.5332588
MaxAttendance: 8
Name: "Parkour 8 ans et +"
Note: null
NumberOfOccurrences: 6
OrganizationId: 1383
PictureUrl: null
Prerequisite: "Aucun"
Price: 144
ProgramName: "MINI SESSION PRINTEMPS 2019"
ScheduleSummary: "Le mercredi de 18:00 à 19:15"
SpotsRemaining: 7
SpotsReserved: 1
StartDate: "2019-05-15T22:00:00Z"
Status: "Normal"
SubCategoryName: "Parkour récréatif"
Tags: null
ThirdPartyUrl: null
Url: "https://www.amilia.com/store/fr/centre-coyotes/shop/activities/2098673"
sport_id: null*/

class Item extends Component {

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(newProps) {

  
    }

    componentDidMount() {

    
    }

    render() {

        let act = this.props.row.Activities.length > 0 ? this.props.row.Activities[0] : null;

        return (
            <>
                
                <Row gutter={16}>
                    <Col md={24} xs={24}>
                        {!act.PictureUrl ? <img width='100%' alt="logo" src="https://images.unsplash.com/photo-1486218119243-13883505764c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=752&q=80" /> : <img width='100%' alt="logo" src={act.PictureUrl} />}
                    </Col>
                </Row>

                <h1>{this.props.row.Name}</h1>
                <p>{`${this.props.row.Address.Address}, ${this.props.row.Address.City}, ${this.props.row.Address.Province}`}</p>
                
                <p></p>
                
                {this.props.row.Activities.map(act => (
                    <p><a target="blank_" href={act.Url}>{act.ScheduleSummary} - {act.Price}$ / session</a></p>)
                )}
                
                <Rate allowHalf defaultValue={3} />

                <p>
                    <br></br>
                    <Button type="dashed" onClick={()=>this.props.back()}><Icon type="left" /> Back</Button>
                </p>
            </>
        );
    }
}

export default Item;
