import React, { Component } from "react";
import Map from './Components/Map';
import List from './Components/List';
import Item from './Components/Item';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';

import axios from 'axios';

import {
  Layout, Menu, Breadcrumb, Icon, Alert, Tooltip, Affix, Input, Row, Col, AutoComplete
} from 'antd';

import "antd/dist/antd.css";
import './App.css';

const {
  Header, Content,
} = Layout;

const DEFAULT_KEYWORDS = 'Gym';

class App extends Component {

  constructor(props) {
    super(props);

      this.state = {
        data : [],
        loading: false,
        locations: [],
        selectedItem : null,
        dataSource: [],
        keywordsData: [],
        keywords: DEFAULT_KEYWORDS,
        swLat: 45.347413630386626,
        swLng: -73.85900248774828,
        neLat: 45.6947632396042,
        neLng: -73.49150980358513,
      };
  }

  search = debounce(() => {
    this.setState({
      loading: true,
    });

    axios.get("//hackathon.benoitmarineau.com/api/locations", {
      params: {
        keyword: this.state.keywords,
        swLatitude: this.state.swLat,
        swLongitude: this.state.neLng,
        neLatitude: this.state.neLat,
        neLongitude: this.state.swLng,
      }
    }).then((res) => {

      let activities = [];

      /*Address: {Address: "50 Avenue Liberté", City: "Candiac", Province: "QC", Country: "CA", PostalCode: "J5R 6X1"}
Address: "50 Avenue Liberté"
City: "Candiac"
Country: "CA"
PostalCode: "J5R 6X1"
Province: "QC"*/

      /*res.data.map((item) => {
        if (item.Activities && item.Activities.length > 0){
          item.Activities.map((act) => {
            act.Latitude = item.Latitude;
            act.Longitude = item.Longitude;
            act.LocationName = item.Name;
            act.LocationAddress = `${item.Address.Address}, ${item.Address.City}, ${item.Address.Province}`
            activities.push(act);
          })
        }
      });*/

      console.log(activities);

      this.setState({
        data: res.data,
        loading: false,
      }, () => {
        // showing the first change
        this.pageChange(1, 6);
      });
    })
  }, 250)

  select = () => {

  }

  onMarkerClick = (itemId) => {
    const item = this.state.locations.find(l => l.Id === itemId);
    this.setState({
      selectedItem: item
    });
  }

  onMapUpdate = (sw, ne) => {
    this.setState({
      swLat: sw.lat(),
      swLng: sw.lng(),
      neLat: ne.lat(),
      neLng: ne.lng(),
    }, () => {
      this.search();
    })
  }

  componentDidMount() {
    this.search();
    this.loadSports();
  }

  loadSports = () => {
    axios.get("//hackathon.benoitmarineau.com/api/keywords").then((res) => {

      this.setState({
        keywordsData: uniqBy(res.data, 'Name'),
      });

    })
  }

  itemClick = (item) => {
    this.setState({
      selectedItem: item
    });
  }

  back = () => {
    this.setState({
      selectedItem: null
    })
  }

  pageChange = (page, size) => {

    let current = (page - 1) * size;
    let to = page * size;

    this.setState(prevState => ({
      locations: prevState.data.slice(current, to)
    }));

  }

  handleAutoCompleteSearch = (value) => {

    let data = this.state.keywordsData.filter(function (person) {
      return person.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });

    let sportsData = [];

    data.map((d) => {
      sportsData.push(d.Name);
    })

    this.setState({
      dataSource: sportsData
    })

  }

  onSearch = (value) => {
    this.setState({
      keywords: value
    }, () => {
      this.search();
    })
  }

  render() {
    return (
     <>
      <Layout>

          <Header className="header" style={{ position: 'fixed', zIndex: 1, width: '100%' }}>

            <Row gutter={16}>
              <Col md={4} xs={24}>
                <h1 style={{color: '#fff', fontFamily: "'Roboto', sans-serif"}} className="pull-left">ACTIVIX</h1>
              </Col>
              <Col md={16} xs={24}>
                <AutoComplete
                  dataSource={this.state.dataSource}
                  style={{marginTop: '15px', width: '100%'}}
                  onSelect={this.onSearch}
                  onSearch={this.handleAutoCompleteSearch}
                  placeholder="What activity you want to practice today..."
                >
                  <Input suffix={<Icon type="search" className="certain-category-icon" />} />
                </AutoComplete>
              </Col>
            </Row>

          </Header>

          <Content style={{ padding: '0 0px', marginTop: 64 }}>
            <Layout style={{ padding: '0px 0', background: '#fff' }}>
              <Content style={{ padding: '0 0px'}}>

              <Row>
                  <Col md={12} xs={24}>
                    {this.state.selectedItem ? (
                     <Item row={this.state.selectedItem} back={this.back} />
                    ) : <List data={this.state.data} loading={this.state.loading} itemClick={this.itemClick} pageChange={this.pageChange} />}
                  </Col>
                  <Col md={12} xs={24}>
                    <Affix offsetTop='10'>
                      <Map
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCC29HnUtTy_SNSKzCr9nWSRvvl2kzs2mU&v=3.exp"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `${window.innerHeight-70}px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        locations={this.state.locations || []}
                        onMarkerClick={this.onMarkerClick}
                        onMapUpdate={this.onMapUpdate}
                      />
                    </Affix>
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Content>
        </Layout>
     </>
    );
  }
}

export default App;
