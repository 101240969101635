import React, { Component } from "react";
import {List, Avatar, Icon, Rate} from 'antd';

const IconText = ({ type, text }) => (
  <span>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);
class ListRes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    componentWillReceiveProps(newProps) {

  
    }

    componentDidMount() {

    
    }

    render() {

        /*if (this.props.data && this.props.data.length > 0){
            this.props.pageChange
        }*/

        return (
            <>
                <List
                className="list"
                  itemLayout="vertical"
                  size="large"
                    pagination={{
                    onChange: (page, size) => {
                        this.props.pageChange(page, size);
                    },
                    pageSize: 6,
                 }}
                  loading={this.props.loading}
                  dataSource={this.props.data}
                  renderItem={item => (
                    <List.Item
                    onClick={()=>this.props.itemClick(item)}
                    key={item.title}
                    //actions={[<IconText type="star-o" text="156" />, <IconText type="like-o" text="156" />, <IconText type="message" text="2" />]}
                    extra={item.Activities.length > 0 && item.Activities[0].PictureUrl ? <img width={220} alt="logo" src={item.Activities[0].PictureUrl} /> : <img width={220} alt="logo" src="https://images.unsplash.com/photo-1486218119243-13883505764c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=752&q=80" />}
                  >
                      <List.Item.Meta 
                        title={item.Name}
                        description={`${item.Address.Address}, ${item.Address.City}, ${item.Address.Province}`}
                      />
                    <p>{item.Activities.length} Sessions</p>
                    <p><a target="blank_" href={item.Url}>{item.Activities.length > 0 ? item.Activities[0].Price : null}$ / sessions</a></p>
                    <Rate allowHalf defaultValue={2.5} />
                </List.Item>
            )}
          />
            </>
        );
    }
}

export default ListRes;
