import React from "react";
import {
  Marker,
} from "react-google-maps";

class MapMarker extends React.Component {
  constructor(props) {
    super(props);
  }

  onClick = () => {
    this.props.onClick(this.props.location.Id);
  }

  render() {
    return (
      <Marker
        position={{
          lat: this.props.location.Latitude,
          lng: this.props.location.Longitude
        }}
        clickable
        onClick={this.onClick}
        label={this.props.location.Name}
      />
    );
  }
}

export default MapMarker;
